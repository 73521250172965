import { memo } from 'react';

import SEOCategories from '@Modals/_components/SEOCategories';

import type { FC } from 'react';
import type { SEOCategoriesProps } from '@Modals/_components/SEOCategories';

const SEOSofasCategoryModal: FC<SEOCategoriesProps> = (props) => {
  return <SEOCategories {...props} />;
};

export default memo(SEOSofasCategoryModal);
